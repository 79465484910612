import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import lottiePlayer from 'lottie-web';
import { useSharedRef } from '../lib/utils';

const Lottie = ({
  className,
  animationData,
  autoplay,
  lazyPlay,
  loop,
  loopDelay,
  name,
  path,
  randomized,
  minDelay,
  maxDelay,
  renderer,
  style,
}) => {
  const containerRef = useRef();
  const anim = useRef();
  const timeout = useRef();
  const [inViewRef, inView ] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const ref = useSharedRef(null, [containerRef, inViewRef]);

  const onAnimationTimeout = useCallback(() => {
    if (!anim.current) {
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    anim.current.stop();
    const delay = minDelay + Math.floor(Math.random() * (maxDelay - minDelay));
    timeout.current = setTimeout(() => anim.current.play(), delay);
  }, [anim, timeout, minDelay, maxDelay]);

  const onDelayedLoop = useCallback(() => {
    if (!anim.current) {
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    anim.current.pause();
    timeout.current = setTimeout(() => anim.current.goToAndPlay(0, true), loopDelay);
  }, [anim, timeout, loopDelay]);

  const onAnimationComplete = useCallback(() => {
    if (randomized) {
      onAnimationTimeout();
    } else if (loop && loopDelay !== undefined) {
      onDelayedLoop();
    }
  }, [randomized, loop, loopDelay, onAnimationTimeout, onDelayedLoop]);

  useEffect(() => {
    if (!anim.current) {
      anim.current = lottiePlayer.loadAnimation({
        container: containerRef.current,
        animationData,
        autoplay: !randomized && autoplay && !lazyPlay,
        loop: !randomized && loop && loopDelay === undefined,
        name,
        path,
        renderer,
      });

      anim.current.addEventListener('complete', onAnimationComplete);
    }

    return () => {
      if (anim.current) {
        anim.current.removeEventListener('complete', onAnimationComplete);
        anim.current.destroy();
      }

      anim.current = null;
    };
  }, [animationData, path, onAnimationComplete]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (randomized) {
      onAnimationTimeout();
    }

    return () => timeout.current ? clearTimeout(timeout.current) : void 0;
  }, [timeout, randomized]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lazyPlay && autoplay && inView && anim.current) {
      console.log('play animation, in view');
      anim.current.play();
    }
  }, [lazyPlay, autoplay, inView]);

  return (
    <div
      className={className}
      style={style}
      ref={ref}
    />
  );
};

Lottie.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  animationData: PropTypes.object,
  autoplay: PropTypes.bool,
  lazyPlay: PropTypes.bool,
  loop: PropTypes.bool,
  loopDelay: PropTypes.number,
  name: PropTypes.string,
  path: PropTypes.string,
  randomized: PropTypes.bool,
  minDelay: PropTypes.number,
  maxDelay: PropTypes.number,
  renderer: PropTypes.oneOf([
    'svg',
    'canvas',
    'html',
  ]),
};

Lottie.defaultProps = {
  className: undefined,
  autoplay: true,
  lazyPlay: false,
  loop: true,
  loopDelay: undefined,
  name: undefined,
  path: undefined,
  randomized: false,
  minDelay: 7 * 1000,
  maxDelay: 20 * 1000,
  renderer: 'svg',
};

export default Lottie;
